// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:56f8f435-b011-4d7e-98bf-9fd75fd6d81f",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_YbGORhXek",
    "aws_user_pools_web_client_id": "2i2nb26gu3r4v92ejab9o1lp56",
    "oauth": {}
};


export default awsmobile;
